import { FlexRowRight, tableHeaderFontStack } from "components/globals";
import styled from "styled-components";

export const StyledStandardButton = styled(FlexRowRight)<{
  has_icon?: boolean;
  icon_side?: "left" | "right";
  margin?: string;
  disabled?: boolean;
}>`
  ${tableHeaderFontStack};
  padding: ${(props) => (props.has_icon ? "4px 20px" : "8px 16px")};
  padding-right: ${(props) =>
    !props.has_icon ? "" : props.icon_side == "left" ? "24px" : "20px"};
  padding-left: ${(props) =>
    !props.has_icon ? "" : props.icon_side == "right" ? "24px" : "20px"};
  background-color: ${(props) =>
    props.disabled ? props.theme.bg.inactive : props.theme.bg.buttonone};
  color: white;
  border-radius: 4px;
  font-size: 0.92rem;
  margin: ${(props) => (props.margin ? props.margin : "0 8px")};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  font-weight: 500;
  gap: 12px;
  white-space: nowrap;

  > i {
    font-weight: 700;
  }

  :hover {
    filter: brightness(93%);
  }
`;
