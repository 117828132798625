import { Article } from "types/article";

export const Articles: Article[] = [
  {
    title: "Pregnancy can be risky in the US. In North Carolina, the threat of death is even higher.",
    body: `In a country that has one of the worst maternal mortality rates among wealthy nations, pregnant women in North Carolina are particularly vulnerable. The troubling situation was exacerbated by the COVID-19 pandemic. While there’s been a slight decline in pregnancy-related deaths and severe injuries since their pandemic peak, health experts remain concerned. This News & Observer and The Charlotte Observer investigation explores the data and the emotional impact.`,
    tags: ["Public Health", "Investigative"],
    date: "July 28th, 2023",
    awards: [],
    paper: "News & Observer",
    download_link: "https://www.newsobserver.com/news/local/article277397263.html",
  },
  {
    title: "How new NC abortion law has ‘taken away’ complex abortion care at a Chapel Hill clinic",
    body: `State lawmakers voted to override Democratic Gov. Roy Cooper’s veto of the 12-week abortion bill. It now takes effect July 1. Meanwhile, clinics, anti-abortion groups, and future doctors are trying to prepare for the future of abortion despite their unanswered questions about the new law.`,
    tags: ["Public Health"],
    date: "June 22nd, 2023",
    awards: [],
    paper: "News & Observer",
    download_link: "https://www.newsobserver.com/article276477731.html",
  },
  {
    title: "Why a Christian anti-abortion group in Texas is in line for $10M in NC taxpayer money",
    body: `When Human Coalition first appeared in the North Carolina state budget in 2017, it received $600,000 over two years to “assist women experiencing crisis pregnancies to continue their pregnancies to full term.” Since then, state funding for the Christian anti-abortion group has increased by $5.8 million — about 965%.`,
    tags: ["Public Health", "Investigative"],
    date: "April 15th, 2023",
    awards: [],
    paper: "News & Observer",
    download_link: "https://www.newsobserver.com/news/politics-government/article273994320.html",
  },
  {
    title:
      "The health care didn’t change. The office hasn’t moved. Why is UNC now charging more?",
    body: `Last month, some UNC Health patients received a letter informing them that three outpatient dermatology clinics would be converted into “hospital-based clinics.” Almost everything about the health care at those clinics would stay the same, the letter assured patients. The location of the clinics, the doctors working there and the care they provided would not change. What will change, the letter pointed out, is how patients are charged for that care.`,
    tags: ["Healthcare"],
    date: "March 13th, 2023",
    awards: [],
    paper: "News & Observer",
    download_link:
      "https://www.newsobserver.com/news/local/article272637989.html",
  },
  {
    title:
      "After 30 years of research, pill developed at Duke for breast cancer approved for use",
    body: `After nearly seven years of painful injections, Stephanie Walker has had enough. Every 28 days, she drives an hour and 45 minutes to Duke’s Cancer center to get 50 mL slowly injected into her glute muscles. The viscous mixture of castor oil and medicine is painful going in and leaves lumps in its wake. Then she drives an hour back to her Tarboro home, seat warmers on to soothe the soreness. Walker, who was diagnosed with metastatic breast cancer in 2015, has endured so many of these injections, the tissue around her right hip has turned necrotic, she said.`,
    tags: ["Science"],
    date: "February 7th, 2023",
    awards: [],
    paper: "News & Observer",
    download_link:
      "https://www.newsobserver.com/news/local/article272034562.html",
  },
  {
    title:
      "Duke Health draws transparency criticism over patient data deal with tech company",
    body: `For each of Duke Health’s hundreds of thousands of patients, the institution keeps an exhaustive record of valuable health data. Every medication they’ve been prescribed, every blood pressure reading or temperature recorded, every diagnosis given, every doctor’s note and images from every MRI or X-ray are all cataloged in Duke’s electronic medical database.`,
    tags: ["Healthcare"],
    date: "February 1st, 2023",
    awards: [],
    paper: "News & Observer",
    download_link: "./Duke_Health.pdf",
  },
  {
    title:
      "‘End of our rope’: Respiratory season pushes day cares, parents to a breaking point",
    body: `When COVID-19 vaccines were finally approved for young children in June, Beth Branciforte thought the days of nose swabs and outbreaks were behind her small preschool in Durham. Branciforte, the director of Branches Community School, saw parents sigh in relief as she announced that they would lift many of strict rules governing when kids had to stay home from school.`,
    tags: ["Public Health"],
    date: "December 12th, 2022",
    awards: [],
    paper: "News & Observer",
    download_link:
      "https://www.newsobserver.com/news/local/article269746786.html",
  },
  {
    title:
      "With NC abortion law in mind, Walmart puts new limits on dispensing miscarriage drug",
    body: `Aiming to comply with this state’s abortion law, Walmart pharmacies are wrongly limiting women’s access to a drug prescribed after miscarriages, North Carolina doctors say. While the drug misoprostol can be used with another medication to induce abortions, it’s also essential for managing a number of unrelated health conditions, including miscarriages.`,
    tags: ["Investigative", "Impact Journalism", "Public Health"],
    date: "September 21st, 2022",
    awards: [],
    paper: "News & Observer",
    download_link: "./Walmart_Story.pdf",
    impact: `All five of North Carolina’s Democratic representatives sent a letter to the CEO of Walmart, demanding to know why the retail chain was violating federal law. Shortly after, Walmart rescinded the policy in question.`,
  },
  {
    title:
      "Psychedelics without the trip: a potential breakthrough for depression treatment",
    body: `When lab mice take psychedelics, their heads twitch, a telltale sign they’re seeing cats or cheese or whatever else rodents hallucinate while tripping. But when Dr. Bryan Roth’s research group injected mice with their newly created psychedelic compound, they noticed something strange: the mice didn’t twitch or show any other signs they were hallucinating.`,
    tags: ["Science"],
    date: "September 29th, 2022",
    awards: [],
    paper: "News & Observer",
    download_link:
      "https://www.newsobserver.com/news/local/article266505596.html",
  },
  {
    title:
      "Duke doctors performed world’s first partial heart transplant on NC newborn",
    body: `The procedure had only ever been performed on pigs, doctors told Tayler and Nick Monroe as they huddled over their dying newborn son. If they elected to go through with the surgery, their son would be the first person to ever undergo a partial heart transplant. If it worked, it might pave the way for thousands of newborns to receive similar procedures.`,
    tags: ["Science"],
    date: "September 12th, 2022",
    awards: [],
    paper: "News & Observer",
    download_link:
      "https://www.newsobserver.com/news/state/north-carolina/article265565691.html",
  },
  {
    title:
      "Durham man’s hurdles to get monkeypox test reveal gaps in North Carolina’s readiness",
    body: `A Durham man with symptoms of monkeypox and many reasons to think he had the virus began calling healthcare providers to get a test on July 1. A week later, after seeking help from his primary care doctor; county, state and federal health officials; an urgent care clinic; and a hospital emergency room, he still had no test.`,
    tags: ["Public Health"],
    date: "July 14th, 2022",
    awards: [],
    paper: "News & Observer",
    download_link:
      "https://www.newsobserver.com/news/local/article263435543.html",
  },
  {
    title:
      "UCLA’s Fight to Patent a Life-Saving Cancer Drug Could Make the Medicine Virtually Unobtainable in India",
    body: `The university once promised to take into account the needs of patients in developing countries. The battle over prostate cancer drug Xtandi has critics questioning its dedication to that pledge`,
    tags: ["Public Health", "Healthcare", "Investigative", "Award-Winning"],
    date: "January 7th, 2020",
    awards: ["Bridget O'Brien Travel Grant, 2019"],
    paper: "Los Angeles Magazine",
    download_link: "https://www.lamag.com/citythinkblog/ucla-xtandi-india",
  },
  {
    title:
      "As Back to School Approaches, Clinics Are Bracing for Cases of “Super Lice”",
    body: `Wearing a hair net and blue latex gloves, Cece Uribe peels back her client’s thick red hair and begins combing out dead lice. After three years in the nitpicking business, she’s seen it all—an infestation so bad the hair was sticky with nits, a patient who had a year-long case that no pharmacy shampoo could cure, even a ten-month-old baby who had caught a stubborn case from daycare.`,
    tags: ["Science"],
    date: "August 13th, 2019",
    awards: [],
    paper: "Los Angeles Magazine",
    download_link: "https://www.lamag.com/citythinkblog/super-lice-los-angeles",
  },
  {
    title:
      "‘Why didn’t anyone help me?’ – A failing mental health system leads to deadly police encounters",
    body: `The homes Dawna Schaub cleaned for more than a decade were overflowing with reminders of the life that had been taken from her — rows of shoes by the door, birthdays circled on the kitchen calendar, graduation photos of beaming children. “There are all these family photos, and I can’t look at that right now,” she said. “I can’t watch their happy endings.” Shortly after her son was shot and killed by police in November 2020, Schaub traded her clientele of well-to-do homeowners for odd jobs at cottages around Lake Winnipesaukee.`,
    tags: [
      "Public Health",
      "Investigative",
      "Impact Journalism",
      "Award-Winning",
    ],
    date: "December 18th, 2021",
    awards: [
      "First place for public health reporting, Association for Health Care Journalists, 2021",
      "First place for investigative story, New Hampshire Press Association, 2021",
      "Editor’s pick for best public health books and journalism, Harvard Public Health Magazine, 2022",
    ],
    paper: "Concord Monitor",
    series: "Shots Fired",
    download_link:
      "https://www.concordmonitor.com/mental-health-police-fatal-encounters-shooting-42709250",
    impact: `In 2022, Gov. Sununu signed a bill that allocated $1.1 million to crisis intervention training for police officers. The law also compelled state legislators to study the creation of a “mental health incident review board” to examine police shootings that involve someone with a mental illness. Proponents of the legislation frequently referenced and cited the Monitor series during hearings.`,
  },
  {
    title:
      "Police are tasked with responding to mental crises. The results can be disastrous for officers and callers alike.",
    body: `Last summer, Kevin O’Reilly sat around the Meredith police station with other officers and talked about a trend they noticed on the local news. Stories of police shootings, specifically those that involved someone in a mental health crisis, seemed to pop onto the television every couple of months.`,
    tags: [
      "Public Health",
      "Investigative",
      "Impact Journalism",
      "Award-Winning",
    ],
    date: "December 19th, 2021",
    awards: [
      "First place for public health reporting, Association for Health Care Journalists, 2021",
      "First place for investigative story, New Hampshire Press Association, 2021",
      "Editor’s pick for best public health books and journalism, Harvard Public Health Magazine, 2022",
    ],
    paper: "Concord Monitor",
    series: "Shots Fired",
    download_link:
      "https://www.concordmonitor.com/mental-illness-police-fatal-shooting-43425130",
    impact: `In 2022, Gov. Sununu signed a bill that allocated $1.1 million to crisis intervention training for police officers. The law also compelled state legislators to study the creation of a “mental health incident review board” to examine police shootings that involve someone with a mental illness. Proponents of the legislation frequently referenced and cited the Monitor series during hearings.`,
  },
  {
    title: "How to stop a crisis before it happens.",
    body: `With a prescription bottle in hand, Karen looked forlornly at the floor and sighed. She couldn’t do it anymore, she told her closest friend. Mounting financial pressure and messy divorce proceedings had driven her to her limit. She downed half a bottle of prescription pills and locked the bathroom door.`,
    tags: [
      "Public Health",
      "Investigative",
      "Impact Journalism",
      "Award-Winning",
    ],
    date: "December 20th, 2021",
    awards: [
      "First place for public health reporting, Association for Health Care Journalists, 2021",
      "First place for investigative story, New Hampshire Press Association, 2021",
      "Editor’s pick for best public health books and journalism, Harvard Public Health Magazine, 2022",
    ],
    paper: "Concord Monitor",
    series: "Shots Fired",
    download_link:
      "https://www.concordmonitor.com/mental-health-police-fatal-shooting-43690060",
    impact: `In 2022, Gov. Sununu signed a bill that allocated $1.1 million to crisis intervention training for police officers. The law also compelled state legislators to study the creation of a “mental health incident review board” to examine police shootings that involve someone with a mental illness. Proponents of the legislation frequently referenced and cited the Monitor series during hearings.`,
  },
  {
    title:
      "His parents brought him for urgent psychiatric help. Then, he waited in the emergency room for nearly a month. ",
    body: `Jeremy Sheppard returned from visiting his son at the emergency room last week to his quaint brick house in Derry. “He’s really depressed, and it’s going not well for him right now,” he told his wife. “Twelve days in isolation will do that.”`,
    tags: ["Public Health"],
    date: "November 6th, 2021",
    awards: [],
    paper: "Concord Monitor",
    download_link:
      "https://www.concordmonitor.com/emergency-room-boarding-mental-health-43224025",
  },
  {
    title:
      "Revolving door at DCYF: ‘As soon as they hire one new person, two people leave’",
    body: `After a decade of working as a child protective services worker at the Manchester district office, Demetrios Tsaros could tell when one of his colleagues was about to quit. They stopped saying good morning as they arrived at the office. They grew dour and quiet. They stifled sobs at their desk as cases continued to pile up.`,
    tags: ["Public Health"],
    date: "September 18th, 2021",
    awards: [],
    paper: "Concord Monitor",
    download_link: "https://www.concordmonitor.com/DCYF-staffing-42243580",
  },
  {
    title:
      "‘I have no idea what’s going to happen’: Caregivers struggle to make ends meet",
    body: `Along Heather Grow’s daily drive to work, she passes three signs advertising open positions. A McDonald’s poster along South Main Street: $15.50 an hour. A white lawn sign outside Concord Auto Spa: $17 an hour.`,
    tags: ["Public Health", "Healthcare"],
    date: "August 21st, 2021",
    awards: [],
    paper: "Concord Monitor",
    download_link: "https://www.concordmonitor.com/homecare-41845295",
  },
  {
    title: "Pandemic disrupted routines of those facing homelessness",
    body: `In a bright room with high ceilings and crimson carpets off the nave of the First Congregational Church in Concord, Julie Green called guests to attention. The church, recently sold and repurposed as a homeless shelter, served as hub for dozens of unhoused individuals. On one of the first warm days of the emerging spring weather, dozens of people donning masks sat around a mounted TV or slumped against gothic arches.`,
    tags: ["Public Health"],
    date: "April 3rd, 2021",
    awards: ["2nd Place, Report for America Local News Award, Feature"],
    paper: "Concord Monitor",
    download_link:
      "https://www.concordmonitor.com/covid-homeless-life-39095936",
  },
  {
    title: "Nursing homes receive loads of defective equipment from FEMA",
    body: `Massive amounts of the personal protective equipment sent to nursing homes by the federal government have been unusable, according to the New Hampshire Health Care Association. Some face masks with paper ear loops were too brittle and broke when stretched. Some shipments included cloth masks, which, according to the CDC, are not considered adequate protection in a clinical setting. A Manchester nursing home administrator even received 1,800 extra-small gloves on Thursday that were nearly useless for a full staff of adults, said Brendan Williams, the president of the New Hampshire Health Care Association.`,
    tags: ["Public Health", "Impact Journalism"],
    date: "June 30th, 2020",
    awards: [],
    paper: "Concord Monitor",
    download_link:
      "https://www.concordmonitor.com/COVID-19-PPE-Federal-35007019",
    impact:
      "One of New Hampshire’s U.S. Senators sent a letter to FEMA’s administration, pressing them to explain the faulty equipment.  Shortly after, FEMA announced they would send a new, improved shipment of PPE. Gov. Chris Sununu also vowed to replace the defective items with the state’s supply of equipment.",
  },
];
