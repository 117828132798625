import {
  altTextFontStack,
  FlexRow,
  SmallSpan,
  Span,
  P,
  tableHeaderFontStack,
  FlexCol,
} from "components/globals";
import styled from "styled-components";

export const ExpandableSectionContainer = styled(FlexCol)`
  border-radius: 6px;
  border: ${(props) => props.theme.line.default};
`;

export const ExpandableSectionHeader = styled(FlexRow)`
  background: ${(props) => props.theme.bg.shadetwo};
  cursor: pointer;
  padding: 4px 14px;

  > i {
    color: ${(props) => props.theme.text.default};
  }
`;

export const ExpandableSectionBody = styled(FlexCol)<{
  gap?: string;
  hidden?: boolean;
}>`
  padding: 20px;
  ${(props) => props.hidden && "display: none;"}
`;

export const ExpandableSectionText = styled(SmallSpan)`
  font-weight: bold;
`;
