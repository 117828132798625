import github from "assets/github.png";
import React, { useState } from "react";
import {
  FlexRow,
  FlexCol,
  MaterialIcon,
  fadeInAnimation,
  FlexColCenter,
  FlexColScroll,
  FlexRowLeft,
  FlexRowRight,
  H3,
  HorizontalSpacer,
  SmallSpan,
} from "components/globals";
import styled from "styled-components";
import { Article } from "types/article";
import { tagColors } from "sections/articlessection/ArticlesSection";

type Props = {
  article: Article;
  select_article?: (article: Article) => void;
};

const ArticlePreview: React.FC<Props> = ({ article, select_article }) => {
  const renderArticlePreviewText = () => {
    if (article.impact) {
      return (
        <ArticlePreviewText>
          <span style={{ fontWeight: "bold" }}>Impact: </span>
          {article.impact}
        </ArticlePreviewText>
      );
    }

    return <ArticlePreviewText>{article.body}</ArticlePreviewText>;
  };

  return (
    <ArticleContainer
      onClick={() => {
        select_article && select_article(article);
      }}
    >
      <FlexRow>
        <FlexRowLeft
          gap="12px"
          style={{ flexWrap: "wrap", overflow: "hidden" }}
        >
          {article.tags.map((tag) => {
            return <Tag color={tagColors[tag]}>{tag}</Tag>;
          })}
        </FlexRowLeft>
        <FlexRowRight gap="12px">
          <SmallSpan style={{ fontWeight: "bold" }}>{article.date}</SmallSpan>
          {article.awards.length > 0 && (
            <MaterialIcon className="fa-solid fa-award"></MaterialIcon>
          )}
        </FlexRowRight>
      </FlexRow>
      <HorizontalSpacer spacepixels={12} />
      <H3>{article.title}</H3>
      <HorizontalSpacer spacepixels={12} />
      {article.series && (
        <SmallSpan style={{ fontStyle: "italic" }}>
          Part of the "{article.series}" series
        </SmallSpan>
      )}
      {article.series && <HorizontalSpacer spacepixels={12} />}
      {renderArticlePreviewText()}
    </ArticleContainer>
  );
};

export default ArticlePreview;

const ArticlesSectionContainer = styled(FlexCol)`
  height: calc(100vh - 80px);
  width: 100%;

  @media (max-width: 1100px) {
    height: fit-content;
  }
`;

const ArticleContainer = styled(FlexCol)`
  ${fadeInAnimation};
  background: white;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 14px;
  margin: 12px 0;
  padding: 16px 24px 24px 24px;
  cursor: pointer;
  height: fit-content;
  min-width: fit-content;
`;

const ArticlePreviewText = styled(SmallSpan)`
  line-height: 1rem;
`;

const FilterRowContainer = styled(FlexRowLeft)`
  flex-wrap: wrap;
`;

const StyledArticlesContainer = styled(FlexRow)`
  align-items: flex-start;

  @media (max-width: 1100px) {
    flex-direction: column;
    height: fit-content;
  }
`;

const StyledFilterContainer = styled(FlexColCenter)`
  height: 100%;
  width: 25vw;
  margin: 0 3vw 0 9vw;
  padding: 0 1vw;

  @media (max-width: 1100px) {
    height: fit-content;
    width: 100%;
    margin-bottom: 30px;
  }
`;

const StyledArticleContainer = styled(FlexColScroll)`
  height: 100%;
  max-height: 600px;
  flex: 1;
  margin-right: 10vw;

  @media (max-width: 1100px) {
    margin-right: 0;
    padding: 0 6%;
  }
`;

const Tag = styled(FlexRow)<{ color?: string }>`
  padding: 6px 12px;
  background: ${(props) => props.color || props.theme.bg.shadetwo};
  border-radius: 4px;
  font-size: 0.84rem;
  color: #444;
  cursor: pointer;
`;
