import github from "assets/github.png";
import React, { useState } from "react";
import { FlexRow, FlexCol, MaterialIcon } from "components/globals";
import {
  ExpandableSectionContainer,
  ExpandableSectionHeader,
  ExpandableSectionText,
  ExpandableSectionBody,
} from "./styles";

type Props = {
  text: string;
  width?: string;
  children?: React.ReactNode;
};

const ExpandableSection: React.FC<Props> = ({ text, children, width }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <ExpandableSectionContainer width={width}>
      <ExpandableSectionHeader onClick={() => setExpanded(!expanded)}>
        <ExpandableSectionText>{text}</ExpandableSectionText>
        <MaterialIcon className="material-icons">
          {expanded ? "expand_less" : "expand_more"}
        </MaterialIcon>
      </ExpandableSectionHeader>
      <ExpandableSectionBody hidden={!expanded} gap="8px">
        {children}
      </ExpandableSectionBody>
    </ExpandableSectionContainer>
  );
};

export default ExpandableSection;
