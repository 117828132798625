import { createGlobalStyle } from "styled-components";

export const theme = {
  bg: {
    default: "#F1F4F0",
    highlight: "#462dcf",
    reverse: "#16171A",
    wash: "#FAFAFA",
    divider: "white",
    border: "white",
    inactive: "#999",
    shadeone: "#00000009",
    shadetwo: "#e0e0e0",
    buttonone: "#45392E",
    hover: "#211F2D",
    hovertwo: "#ffffff0b",
  },
  font: {
    header: "Patua One",
    text: "Work Sans",
  },
  line: {
    default: "1px solid #00000022",
    thick: "2px solid #000000aa",
    selected: "1px solid #ffffffaa",
  },
  text: {
    default: "black",
    alt: "black",
    inactive: "#999999aa",
  },
};

export default theme;

export const GlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box;
  font-family: 'Work Sans', sans-serif;
}
body {
  background: ${theme.bg.default};
}
html, body {
  overscroll-behavior-x: none;
  overflow-x: hidden;
}
a {
  text-decoration: none;
}
img {
  max-width: 100%;
}
`;
