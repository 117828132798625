import { FlexRow, FlexRowLeft } from "components/globals";
import React from "react";
import { TopBarProductName, TopBarWrapper } from "./styles";

type Props = {
  children?: React.ReactNode;
};

const NavBar: React.FunctionComponent<Props> = ({ children }) => {
  return (
    <TopBarWrapper>
      <FlexRow>
        <FlexRowLeft>
          <TopBarProductName>Teddy Rosenbluth</TopBarProductName>
        </FlexRowLeft>
        {children}
      </FlexRow>
    </TopBarWrapper>
  );
};

export default NavBar;
