import { MaterialIcon } from "components/globals";
import { StyledStandardButton } from "./styles";

type Props = {
  material_icon?: string;
  id?: string;
  label: string;
  icon_side?: "left" | "right";
  margin?: string;
  link?: string;
};

const StandardButton: React.FC<Props> = ({
  id,
  material_icon,
  label,
  icon_side = "left",
  margin,
  link,
}) => {
  const children = [
    <MaterialIcon key="0" className="material-icons">
      {material_icon}
    </MaterialIcon>,
    label,
  ];

  if (link) {
    return (
      <a href={link} target="_blank">
        <StyledStandardButton
          id={id}
          has_icon={true}
          icon_side={icon_side}
          margin={margin}
        >
          {icon_side == "left" ? children : children.reverse()}
        </StyledStandardButton>
      </a>
    );
  }

  return (
    <StyledStandardButton
      id={id}
      has_icon={true}
      icon_side={icon_side}
      margin={margin}
    >
      {icon_side == "left" ? children : children.reverse()}
    </StyledStandardButton>
  );
};

export default StandardButton;
