import {
  FlexCol,
  FlexColCenter,
  FlexRow,
  FlexRowLeft,
  H1,
  FlexColVerticalCenter,
  SmallSpan,
  P,
  Span,
  HorizontalSpacer,
  FlexRowRight,
  MaterialIcon,
  fadeInAnimation,
} from "components/globals";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import teddy from "assets/teddy.jpeg";
import StandardButton from "components/button";
import ExpandableSection from "components/expandablesection";
import { Link } from "react-router-dom";

const ResumeSection: React.FC = () => {
  return (
    <ResumeSectionContainer>
      <HorizontalSpacer spacepixels={30} />
      <H1>Resumé</H1>
      <HorizontalSpacer spacepixels={30} />
      <FullResumeContainer height="100%">
        <FlexCol className="column" height="100%">
          <StyledLabel>Experience</StyledLabel>
          <HorizontalSpacer spacepixels={6} />
          <SmallSpan style={{ fontWeight: "bold" }}>
            News &amp; Observer | Science and Health Reporter| Jun. 2022-Present
          </SmallSpan>
          <HorizontalSpacer spacepixels={6} />
          <SmallSpan>
            • Broke the story that Walmart pharmacies in N.C. were illegally
            restricting access to abortion pills. The investigation caused the
            pharmacy chain to rescind the statewide internal policy in question.
          </SmallSpan>
          <HorizontalSpacer spacepixels={6} />
          <SmallSpan>
            • Regularly covered scientific papers from local universities (Duke
            and UNC) and medical advancements, like FDA drug approvals and
            groundbreaking surgeries.
          </SmallSpan>
          <HorizontalSpacer spacepixels={6} />
          <SmallSpan>
            • Reported on the state’s major healthcare issues, including the new
            Medicaid expansion deal, the state’s rapidly changing abortion
            landscape and simultaneous public health crises.
          </SmallSpan>
          <HorizontalSpacer spacepixels={24} />
          <SmallSpan style={{ fontWeight: "bold" }}>
            Concord Monitor / Report for America | Staff Health Reporter| Jun.
            2020-Present
          </SmallSpan>
          <HorizontalSpacer spacepixels={6} />
          <SmallSpan>
            • Published an award-winning investigation into deadly police
            shootings, which revealed that 60% of those shot and killed in New
            Hampshire had a mental illness.
          </SmallSpan>
          <HorizontalSpacer spacepixels={6} />
          <SmallSpan>
            • Broke the story of N.H. nursing homes receiving defective PPE from
            FEMA, prompting the state’s delegation to send a letter to the
            federal agency.
          </SmallSpan>
          <HorizontalSpacer spacepixels={6} />
          <SmallSpan>
            • Wrote between three and five stories a week, heading the
            coronavirus coverage, following health legislation from the
            statehouse, and featuring the stories of the state’s most vulnerable
            residents.
          </SmallSpan>
          <HorizontalSpacer spacepixels={24} />
          <SmallSpan style={{ fontWeight: "bold" }}>
            Los Angeles Magazine | Editorial Intern/Freelancer | Jun. 2019-Jan
            2020
          </SmallSpan>
          <HorizontalSpacer spacepixels={6} />
          <SmallSpan>
            • Traveled to New Delhi to write and publish a 3,000-word
            investigation into a patent war waged by UCLA that made cancer
            medication unaffordable to patients in India.
          </SmallSpan>
          <HorizontalSpacer spacepixels={6} />
          <SmallSpan>
            • Pitched and published articles on an amalgam of science and health
            topics—from drug-resistant lice to a grueling virtual reality
            exposure therapy to treat PTSD. 
          </SmallSpan>
          <HorizontalSpacer spacepixels={6} />
          <SmallSpan>
            • Fact-checked articles for the website and magazine, including the
            monthly cover stories. 
          </SmallSpan>
        </FlexCol>
        <FlexCol className="column" height="100%">
          <StyledLabel>Education</StyledLabel>
          <HorizontalSpacer spacepixels={6} />
          <SmallSpan style={{ fontWeight: "bold" }}>
            UCLA | Psychobiology | Jun. 2020
          </SmallSpan>
          <HorizontalSpacer spacepixels={6} />
          <SmallSpan>
            • Completed a rigorous science curriculum while conducting research
            in a neuroscience lab.
          </SmallSpan>
          <HorizontalSpacer spacepixels={6} />
          <SmallSpan>
            • Worked for the student newspaper, the Daily Bruin, as a science
            and health reporter and editor. One article was a national finalist
            for the Society for Professional Journalists’ best magazine award.
          </SmallSpan>
          <HorizontalSpacer spacepixels={40} />
          <StyledLabel>Awards</StyledLabel>
          <HorizontalSpacer spacepixels={6} />
          <SmallSpan>
            • 1st place for public health reporting, Association for Health Care
            Journalists, 2021
          </SmallSpan>
          <HorizontalSpacer spacepixels={6} />
          <SmallSpan>
            • 1st place for investigative story, New Hampshire Press
            Association, 2021
          </SmallSpan>
          <HorizontalSpacer spacepixels={6} />
          <SmallSpan>
            • Editor’s pick for best public health books and journalism, Harvard
            Public Health Magazine, 2022
          </SmallSpan>
          <HorizontalSpacer spacepixels={6} />
          <SmallSpan>
            • Newcomer of the Year, News &amp; Observer, 2022
          </SmallSpan>
        </FlexCol>
      </FullResumeContainer>
    </ResumeSectionContainer>
  );
};

export default ResumeSection;

const ResumeSectionContainer = styled(FlexCol)`
  height: calc(100vh - 80px);
  width: 100%;
  padding: 0 10vw;

  @media (max-width: 1100px) {
    height: fit-content;
  }
`;

const FullResumeContainer = styled(FlexRow)`
  flex-wrap: wrap;
  width: 100%;

  .column {
    width: 50%;
    padding: 20px;
    height: 100%;
  }

  @media (max-width: 1100px) {
    flex-direction: column;
    height: fit-content;

    .column {
      height: fit-content;
      width: 100%;
    }
  }
`;

const StyledLabel = styled(SmallSpan)`
  color: #999;
  font-weight: bold;
`;
