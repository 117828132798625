import github from "assets/github.png";
import React, { useState } from "react";
import { FlexRow, FlexCol, MaterialIcon } from "components/globals";
import styled from "styled-components";

type Props = {
  label: string;
  select: () => void;
  unselect: () => void;
  color?: string;
};

const FilterButton: React.FC<Props> = ({ label, select, unselect, color }) => {
  const [selected, setSelected] = useState(false);

  return (
    <StyledFilterButton
      selected={selected}
      color={color}
      onClick={() => {
        if (selected) {
          unselect();
        } else {
          select();
        }

        setSelected(!selected);
      }}
    >
      {label}
    </StyledFilterButton>
  );
};

export default FilterButton;

const StyledFilterButton = styled(FlexRow)<{
  selected?: boolean;
  color?: string;
}>`
  padding: 6px 12px;
  background: ${(props) => props.color || props.theme.bg.shadetwo};
  border-radius: 4px;
  font-size: 0.84rem;
  color: #444;
  cursor: pointer;

  ${(props) => props.selected && `border: 1px solid #444;`}
`;
