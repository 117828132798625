import React from "react";
import logo from "./logo.svg";
import "./App.css";
import styled, { ThemeProvider } from "styled-components";
import theme, { GlobalStyle } from "theme";
import ProfileSection from "sections/profilesection/ProfileSection";
import { FlexColScroll } from "components/globals";
import AppWrapper from "components/appwrapper";
import NavBar from "components/navbar";
import ArticlesSection from "sections/articlessection/ArticlesSection";
import ResumeSection from "sections/resumesection/ResumeSection";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <NavBar />
      <AppWrapper>
        <Scrollable>
          <ProfileSection />
          <ArticlesSection />
          <ResumeSection />
        </Scrollable>
      </AppWrapper>
    </ThemeProvider>
  );
}

export default App;

const Scrollable = styled.div`
  overflow-x: auto;
  padding-right: 12px;
  width: 100%;
`;
