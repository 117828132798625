import {
  FlexCol,
  FlexColCenter,
  FlexRow,
  FlexRowLeft,
  H1,
  FlexColVerticalCenter,
  SmallSpan,
  P,
  Span,
  HorizontalSpacer,
  FlexRowRight,
  MaterialIcon,
  FlexColScroll,
  H2,
  H3,
  H4,
  fadeInAnimation,
} from "components/globals";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import teddy from "assets/teddy.jpeg";
import StandardButton from "components/button";
import ExpandableSection from "components/expandablesection";
import FilterButton from "components/filterbutton";
import ArticlePreview from "components/articlepreview";
import { Articles } from "./articles";
import ExpandedArticle from "components/expandedarticle";
import { Article } from "types/article";
import BackText from "components/backtext";

const tags = [
  "Public Health",
  "Science",
  "Healthcare",
  "Investigative",
  "Award-Winning",
  "Impact Journalism",
];

export const tagColors: {
  [name: string]: string;
} = {
  "Public Health": "#C7CFD9",
  Science: "#BFAC95dd",
  Healthcare: "#8C756144",
  Investigative: "#D91C0B44",
  "Impact Journalism": "#78827966",
  "Award-Winning": "#D0AB9F66",
};

const papers = ["News & Observer", "Concord Monitor", "Los Angeles Magazine"];

const ArticlesSection: React.FC = () => {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [selectedPapers, setSelectedPapers] = useState<string[]>([]);
  const [selectedArticle, setSelectedArticle] = useState<Article | null>(null);

  const selectTag = (topic: string) => {
    const newSelectedTags = selectedTags.map((topic) => topic);
    newSelectedTags.push(topic);

    setSelectedTags(newSelectedTags);
  };

  const unselectTag = (topic: string) => {
    const newSelectedTags = selectedTags.map((topic) => topic);

    newSelectedTags.splice(newSelectedTags.indexOf(topic), 1);

    setSelectedTags(newSelectedTags);
  };

  const selectPaper = (paper: string) => {
    const newSelectedPapers = selectedPapers.map((paper) => paper);
    newSelectedPapers.push(paper);

    setSelectedPapers(newSelectedPapers);
  };

  const unselectPaper = (paper: string) => {
    const newSelectedPapers = selectedPapers.map((paper) => paper);

    newSelectedPapers.splice(newSelectedPapers.indexOf(paper), 1);

    setSelectedPapers(newSelectedPapers);
  };

  const articles = Articles.filter((article) => {
    if (selectedTags.length > 0) {
      if (!selectedTags.some((r) => article.tags.indexOf(r) >= 0)) {
        return null;
      }
    }

    if (selectedPapers.length > 0) {
      if (!selectedPapers.includes(article.paper)) {
        return null;
      }
    }

    return article;
  });

  const renderArticles = () => {
    if (articles.length == 0) {
      return (
        <Placeholder>
          <SmallSpan>No articles found.</SmallSpan>
        </Placeholder>
      );
    }

    return (
      <>
        {articles.map((article) => {
          return (
            <ArticlePreview
              article={article}
              select_article={(article) => {
                setSelectedArticle(article);
              }}
            />
          );
        })}
      </>
    );
  };

  const renderArticlesInner = () => {
    if (selectedArticle) {
      return (
        <StyledArticleContainer>
          <BackText
            text="All Articles"
            back={() => {
              setSelectedArticle(null);
            }}
          />
          <HorizontalSpacer spacepixels={20} />
          <ExpandedArticle article={selectedArticle} />
        </StyledArticleContainer>
      );
    }

    return <StyledArticleContainer>{renderArticles()}</StyledArticleContainer>;
  };

  return (
    <ArticlesSectionContainer>
      <HorizontalSpacer spacepixels={30} />
      <H1
        style={{
          marginLeft: "10vw",
        }}
      >
        Selected Work
      </H1>
      <HorizontalSpacer spacepixels={12} />
      <SmallSpan style={{ fontStyle: "italic", marginLeft: "10vw" }}>
        Click on an article to view awards, impact, and a link to the full
        article.
      </SmallSpan>
      <HorizontalSpacer spacepixels={30} />
      <StyledArticlesContainer width="100%" height="100%">
        <StyledFilterContainer>
          <HorizontalSpacer spacepixels={30} />
          <FlexRow width="100%">
            <H4>Tags</H4>
          </FlexRow>
          <HorizontalSpacer spacepixels={12} />
          <FilterRowContainer width="100%" gap="14px">
            {tags.map((tag) => {
              return (
                <FilterButton
                  color={tagColors[tag]}
                  label={tag}
                  select={() => selectTag(tag)}
                  unselect={() => unselectTag(tag)}
                />
              );
            })}
          </FilterRowContainer>
          <HorizontalSpacer spacepixels={30} />
          <FilterRowContainer width="100%">
            <H4>Paper</H4>
          </FilterRowContainer>
          <HorizontalSpacer spacepixels={12} />
          <FilterRowContainer width="100%" gap="14px">
            {papers.map((paper) => {
              return (
                <FilterButton
                  label={paper}
                  select={() => selectPaper(paper)}
                  unselect={() => unselectPaper(paper)}
                />
              );
            })}
          </FilterRowContainer>
        </StyledFilterContainer>
        {renderArticlesInner()}
      </StyledArticlesContainer>
    </ArticlesSectionContainer>
  );
};

export default ArticlesSection;

const ArticlesSectionContainer = styled(FlexCol)`
  min-height: calc(100vh - 80px);
  height: fit-content;
  width: 100%;

  @media (max-width: 1100px) {
    height: fit-content;
  }
`;

const SelectedArticleContainer = styled(FlexRow)`
  height: fit-content;
  width: 100%;
`;

const ArticleContainer = styled(FlexCol)`
  ${fadeInAnimation};
  background: white;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 14px;
  margin: 12px 0;
  padding: 16px 24px 24px 24px;
  cursor: pointer;
  overflow: hidden;
  height: fit-content;
`;

const FilterRowContainer = styled(FlexRowLeft)`
  flex-wrap: wrap;
`;

const StyledArticlesContainer = styled(FlexRow)`
  align-items: flex-start;

  @media (max-width: 1100px) {
    flex-direction: column;
    height: fit-content;
  }
`;

const StyledFilterContainer = styled(FlexColCenter)`
  height: 100%;
  width: 25vw;
  margin: 0 3vw 0 9vw;
  padding: 0 1vw;

  @media (max-width: 1100px) {
    height: fit-content;
    width: 100%;
    margin-bottom: 30px;
  }
`;

const StyledArticleContainer = styled(FlexColScroll)`
  height: 100%;
  max-height: 600px;
  flex: 1;
  margin-right: 10vw;

  @media (max-width: 1100px) {
    margin-right: 0;
    padding: 0 6%;
  }
`;

const Tag = styled(FlexRow)`
  padding: 6px 12px;
  background: ${(props) => props.theme.bg.shadetwo};
  border-radius: 4px;
  font-size: 0.84rem;
  color: #444;
  cursor: pointer;
`;

const Placeholder = styled(FlexColVerticalCenter)`
  background: ${(props) => props.theme.bg.shadeone};
  height: 200px;
  width: 100%;
  border-radius: 12px;
`;
