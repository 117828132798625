import {
  FlexCol,
  FlexColCenter,
  FlexRow,
  FlexRowLeft,
  H1,
  FlexColVerticalCenter,
  SmallSpan,
  P,
  Span,
  HorizontalSpacer,
  FlexRowRight,
  MaterialIcon,
  fadeInAnimation,
} from "components/globals";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import teddy from "assets/teddy.jpeg";
import StandardButton from "components/button";
import ExpandableSection from "components/expandablesection";
import { Link } from "react-router-dom";

const ProfileSection: React.FC = () => {
  return (
    <StyledProfileBackground width="100%">
      <StyledOverviewSection height="100%" width="100%">
        <H1>Teddy Rosenbluth</H1>
        <HorizontalSpacer spacepixels={12} />
        <FlexRowLeft gap="80px">
          <FlexCol>
            <StyledLabel>City</StyledLabel>
            <P>Raleigh, NC</P>
          </FlexCol>
          <FlexCol>
            <StyledLabel>Currently at</StyledLabel>
            <P>News &amp; Observer</P>
          </FlexCol>
        </FlexRowLeft>
        <HorizontalSpacer spacepixels={12} />
        <Span>
          Teddy Rosenbluth is an award-winning science and health reporter at
          the Raleigh News &#38; Observer.
        </Span>
        <HorizontalSpacer spacepixels={20} />
        <FlexRow width="100%">
          <StandardButton
            label="Contact"
            material_icon="send"
            icon_side="left"
            margin="0"
            link="mailto:teddyrosenbluth@gmail.com"
          />
          <SocialButton
            href="https://twitter.com/teddyrosenbluth"
            target="_blank"
          >
            <MaterialIcon className="fa-brands fa-twitter"></MaterialIcon>
          </SocialButton>
        </FlexRow>
        <HorizontalSpacer spacepixels={20} />
        <ExpandableSection
          text="About"
          width="100%"
          children={[
            <FlexRow gap="20px">
              <P>
                Teddy Rosenbluth is an award-winning science and health reporter
                at the Raleigh News &#38; Observer, covering everything from
                hospital finances to scientific breakthroughs.
                <br />
                <br />
                Her investigative reporting has brought her everywhere from the
                streets of Los Angeles to the hospitals of New Delhi. She
                graduated from UCLA with a bachelor’s degree in psychobiology.
              </P>
            </FlexRow>,
          ]}
        />
        <HorizontalSpacer spacepixels={20} />
        <ExpandableSection
          text="Awards and Fellowships"
          width="100%"
          children={[
            <SmallSpan style={{ fontWeight: "bold" }}>Awards</SmallSpan>,
            <SmallSpan>
              • First place for public health reporting,{" "}
              <a
                href="https://healthjournalism.org/awards-winners.php?Year=2021#cat-41"
                target="_blank"
              >
                Association for Health Care Journalists
              </a>
              , 2021
            </SmallSpan>,
            <SmallSpan>
              • First place for investigative story,{" "}
              <a
                href="https://pressnh.org/wp-content/uploads/2022/06/Complete-List-of-2021-Winners.pdf?mc_cid=285856df37&mc_eid=UNIQID"
                target="_blank"
              >
                New Hampshire Press Association
              </a>
              , 2021
            </SmallSpan>,
            <SmallSpan>
              • Editor’s pick for best public health books and journalism,{" "}
              <a
                href="https://harvardpublichealth.org/reviews/the-best-public-health-books-and-journalism-of-2022/"
                target="_blank"
              >
                Harvard Public Health Magazine
              </a>
              , 2022
            </SmallSpan>,
            <SmallSpan>
              • Newcomer of the Year,{" "}
              <a
                href="https://www.newsobserver.com/news/local/article270119717.html#:~:text=Newcomer%20of%20the%20Year%3A%20Teddy%20Rosenbluth"
                target="_blank"
              >
                News & Observer
              </a>
              , 2022
            </SmallSpan>,
            <br />,
            <SmallSpan style={{ fontWeight: "bold" }}>Fellowships</SmallSpan>,
            <SmallSpan>
              •{" "}
              <a
                href="https://www.dowjonesnewsfund.org/news/early-career-training-launches/"
                target="_blank"
              >
                Dow Jones News Fund
              </a>{" "}
              Early Career Fellow, 2023
            </SmallSpan>,
            <SmallSpan>
              •{" "}
              <a
                href="https://nationalpress.org/newsfeed/npf-selects-20-journalism-fellowship-2022-your-body-their-data-privacy/"
                target="_blank"
              >
                National Press Foundation fellowship
              </a>{" "}
              "Your Body, Their Data?”, 2022
            </SmallSpan>,
            <SmallSpan>
              • Selected member of the 2020{" "}
              <a
                href="https://www.bluecrossmafoundation.org/programs/health-coverage-fellowship"
                target="_blank"
              >
                Health Coverage Fellowship
              </a>{" "}
              class, a 12-person, nationwide group of journalists
            </SmallSpan>,
          ]}
        />
      </StyledOverviewSection>
      <StyledImageSection height="100%" width="100%">
        <StyledImgContainer>
          <StyledImg src={teddy} />
        </StyledImgContainer>
      </StyledImageSection>
    </StyledProfileBackground>
  );
};

export default ProfileSection;

const StyledImg = styled.img`
  ${fadeInAnimation};
  border-radius: 12px;
  width: 26vw;
  min-width: 275px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
`;

const StyledImgContainer = styled.div`
  padding: 120px;
  border-radius: 100%;
  background: #f1f4f0;
  background-image: radial-gradient(#d5ded1 2px, #f1f4f0 0);
  background-size: 16px 16px;
  background-position: -9 -9;

  @media (max-width: 1100px) {
    margin-bottom: 40px;
  }

  @media (max-width: 800px) {
    background: none;
    padding: 0;
    align-items: center;
  }
`;

const StyledLabel = styled(SmallSpan)`
  color: #aaa;
  font-weight: bold;
`;

const StyledImageSection = styled(FlexColVerticalCenter)`
  padding-right: 10vw;

  @media (max-width: 1300px) {
    padding: 0 2vw;
  }

  @media (max-width: 800px) {
    padding: 0;
    align-items: center;
  }
`;

const StyledOverviewSection = styled(FlexColVerticalCenter)`
  ${fadeInAnimation};
  align-items: flex-start;
  padding-left: 10vw;

  @media (max-width: 1300px) {
    padding: 0 2vw;
  }

  @media (max-width: 800px) {
    padding: 0;
    width: 100%;
  }
`;

const SocialButton = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  background-color: ${(props) => props.theme.bg.inactive};
  cursor: pointer;

  > i {
    color: white;
    font-size: 14px;
  }
`;

const StyledProfileBackground = styled(FlexRow)`
  gap: 6vw;
  min-height: calc(100vh - 80px);
  height: fit-content;

  @media (max-width: 1100px) {
    flex-direction: column-reverse;
    padding: 10% 10%;
    height: fit-content;
  }
`;

const StyledAboutMeContainer = styled(FlexRow)`
  width: 500px;

  @media (max-width: 800px) {
    width: 100%;
  }
`;
